*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App{
  overflow-x: hidden;
}

.page-section{
  width: 100vw;
  min-height: 100vh;
  padding: 5vh 5vw 5vh 15vw;
}

.hero{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 5vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.hero ul{
  list-style-type: none;
  display: flex;
  flex-direction: row;
  gap: 2vw;
}

.hero ul li{
  font-size: 2vh;
  margin-top: 2vh;
  color: #ffffff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  transition: 0.25s;
}

.hero ul li:hover{
  letter-spacing: 0.1vw;
}

.about{
  background-color: #ff0000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}

.text-block h3{
  font-size: 4rem;
  color: #ffffff;
}

.text-block p{
  color: #ffffff;
}

.text-block{
  width: 90vw;
  max-width: 800px;
}

.text-block:nth-of-type(2){
  align-self: flex-end;
  text-align: right;
}

.services{
  padding: 0;
  padding-top: 10px;
}

.service-header{
  width: 100%;
  height: 30vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.service-header-overlay{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 2vh 5vw;
  gap: 1vw;
  background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))
}

.service-header-overlay button{
  font-size: 1.5rem;
  border: 5px solid #ff0000;
  padding: 0.5rem;
  font-weight: 800;
  color: #ffffff;
  background-color: #ff0000;
  cursor: pointer;
  transition: 0.1s;
}

.service-header .active{
  background-color: #ffffff;
  color: #ff0000;
}

.service-content{
  width: 90vw;
  margin: auto;
  max-width: 1000px;
}

.service-list {
  list-style-type: disc;
  display: flex;
  flex-wrap: wrap;
}

.service-list li {
  flex: 1 1 45%; /* Two columns */
  margin-bottom: 10px;
  font-size: 1.5rem;
}

.service-list li::marker{
  color: #ff0000;
}


.contact{
  background-color: #ff0000;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.contact-side{
  width: 90vw;
  max-width: 600px;
}

.contact-side h3{
  font-size: 6rem;
  color: #ffffff;
  line-height: 80%;
  margin-bottom: 2vh;
}

.contact-side p{
  font-size: 2rem;
  color: #ffffff;
}

form label{
  color: #ffffff;
}

form input[type=text]{
  width: 100%;
  padding: 0.5rem;
  border: none;
  margin-bottom: 2vh;
}

form textarea{
  width: 100%;
  padding: 0.5rem;
  border: none;
  margin-bottom: 2vh;
  height: 6rem;
}

form button{
  padding: 0.5rem 2rem;
  background-color: #000000;
  color: #ffffff;
  border: none;
  font-weight: 800;
  font-size: 1.5rem;
  cursor: pointer;
}

form button:hover{
  background-color: #ffffff;
  color: #000000;
}

.dynamic-stripe{
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 99;
}

.dynamic-stripe p{
  position: absolute;
  color: #000000;
  font-size: 1rem;
  background-color: #ffffff;
  bottom: 2vh;
  left: 0;
  z-index: 100;
  padding: 1rem;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.25);
}

.dynamic-stripe div{
  width: 1.5rem;
  height: 100%;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 4rem;
}

.dynamic-stripe div:nth-last-of-type(2){
  left: 3rem;
  width: 0.5rem;
}

.nav{
  width: 100vw;
  height: 10vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ffffff;
  padding: 0 5vw;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 100;
  transition: 0.5s;
}

.hidden{
  transform: translateY(-10vh);
}

.nav ul{
  list-style-type: none;
  display: flex;
  gap: 2vw;
}

.nav ul li{
  cursor: pointer;
}

@media (max-width: 768px) {
  .page-section{
    padding: 5vh 2vw;
  }
  .service-list {
    flex-direction: column; /* Switch to column direction */
  }
  .dynamic-stripe{
    display: none;
  }
  .text-block{
    text-align: center;
    align-self: center;
  }
  .text-block h3{
    font-size: 7vw;
  }
  .text-block:nth-of-type(2){
    align-self: center;
    text-align: center;
  }
}